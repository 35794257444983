export function getDisplay() {
  var $items = document.querySelectorAll("[data-hwc-display]");

  $items.forEach((el) => {
    if (el.style.display === "block") {
      return $items[i].dataset.hwcDisplay;
    }
  });

  return null;
}
