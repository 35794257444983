import { tns } from "../lib/tiny-slider/tiny-slider.js";

export function initSlider(slider) {
   
  if (slider.state || slider.config.container.children.length <= 1){
    return slider;
  } 
 
  slider.elm = tns(slider.config);
  slider.state = true;

  return slider;
}

export function destroySlider(slider) {

  if (!slider.state){
    return slider; 
  }
  
  slider.elm.destroy(); 
  slider.elm = null; 

  slider.state = false;

  return slider;
}
 