import $ from 'jquery';
import prestashop from 'prestashop';
import {psGetRequestParameter} from './common';
import VariantsColor from "./components/product/list/VariantsColor";

// Used to be able to abort request if user modify something
var currentRequest = null;

// Used to clearTimeout if user flood the product quantity input
var currentRequestDelayedId = null;

/**
 * Get product update URL from different
 * sources if needed (for compatibility)
 *
 * @return {Promise}
 */
function getProductUpdateUrl() {
  console.log('getProductUpdateUrl');
  let dfd = $.Deferred();
  const $productActions = $('#hwc-js-ProductActions');
  const $quantityWantedInput = $('#quantity_wanted');

  if (prestashop !== null
      && prestashop.urls !== null
      && prestashop.urls.pages !== null
      && prestashop.urls.pages.product !== ''
      && prestashop.urls.pages.product !== null
  ) {
    dfd.resolve(prestashop.urls.pages.product);

    return dfd.promise();
  }
  let formData = {}; 

  $($productActions.find('form:first').serializeArray()).each((k, v) => {
    formData[v.name] = v.value;
  });

  $.ajax({
    url: $productActions.find('form:first').attr('action'),
    method: 'POST',
    data: Object.assign(
      {
        ajax: 1,
        action: 'productrefresh',
        quantity_wanted: $quantityWantedInput.val()
      },
      formData
    ),
    dataType: 'json',
    success(data) {
      let productUpdateUrl = data.productUrl;
      prestashop.page.canonical = productUpdateUrl;
      dfd.resolve(productUpdateUrl);
    },
    error(jqXHR, textStatus, errorThrown) {
      dfd.reject({"jqXHR": jqXHR, "textStatus": textStatus, "errorThrown": errorThrown});
    }
  });

  return dfd.promise();
}

/**
 * @param {string} errorMessage
 */
function showErrorNextToAddtoCartButton(errorMessage) {
  if (errorMessage === undefined) {
    errorMessage = 'An error occurred while processing your request';
  }

  showError(
      $('.quickview .hwc-js-ProductAddToCart-availability, .page-product:not(.modal-open) .hwc-js-ProductAddToCart-availability'),
      errorMessage
  );
}


/**
 * HereWeCom
 * Update the miniatures/product.tpl
 *
 * @param {string} event
 * @param {string} eventType
 * @param {string} updateUrl
 */ 
function updateProductMiniature(event, eventType, updateUrl, reason) {
 
  const miniatureElms = $('.hwc-js-ProductItem[data-id-product="'+reason.productId+'"]') 
  const miniatureElmsDom = document.querySelectorAll('.hwc-js-ProductItem[data-id-product="'+reason.productId+'"]') 
  const $productActions = miniatureElms.find('.hwc-js-ProductItem-actions');
  const $quantityWantedInput = $productActions.find('.hwc-js-ProductQtyWanted');
  const formSerialized = $productActions.find('form:first').serialize();  


  // Can not get product ajax url
  if (updateUrl === null) {
    showErrorNextToAddtoCartButton();

    return;
  } 

  if (currentRequestDelayedId) {
    clearTimeout(currentRequestDelayedId);
  } 

  let updateDelay = 30; 

  currentRequestDelayedId = setTimeout(function updateProductRequest() {

    if (formSerialized === '') {
      return;
    }

    currentRequest = $.ajax({
      url: updateUrl + ((updateUrl.indexOf('?') === -1) ? '?' : '&') + formSerialized,
      method: 'POST',
      data: { 
        ajax: 1,
        action: 'refresh',
        quantity_wanted: $quantityWantedInput.val()
      },
      dataType: 'json',
      beforeSend() {
        if (currentRequest !== null) {
          currentRequest.abort();
        }
      },
      error(jqXHR, textStatus, errorThrown) {
        if (textStatus !== 'abort'
            && $('section#main > .ajax-error').length === 0
        ) { 
          showErrorNextToAddtoCartButton();
        }
      },
      success(data, textStatus, errorThrown) {

        miniatureElms.each(function( index ) {

          let miniatureEl = $(this);
          miniatureEl.find('.hwc-js-ProductItem-prices').first().replaceWith(data.product_miniature_prices);
          miniatureEl.find('.hwc-js-ProductItem-addtocart').first().replaceWith(data.product_miniature_add_to_cart);     
          miniatureEl.find('.hwc-js-ProductItem-additionalInfo').first().replaceWith(data.product_miniature_additional_info);     
          miniatureEl.find('.hwc-js-ProductItem-variants').first().replaceWith(data.product_miniature_variants);  
 
        });
        

        miniatureElmsDom.forEach((miniatureEl) => {
          //slider colors 
          const variantsColorElms = miniatureEl.querySelectorAll("[data-hwcis-variantscolor]");
          if (variantsColorElms) {
            variantsColorElms.forEach((el) => {   
              const variantsColor = new VariantsColor(); 
              variantsColor.init(el); 
            });
          }  
        });

        
      }, 
      complete(jqXHR, textStatus) {
        currentRequest = null; 
        currentRequestDelayedId = null;
      }
    });
  }.bind(currentRequest, currentRequestDelayedId), updateDelay);
}
//HereWeCom
    
/**
 * Update the product html
 * 
 * @param {string} event
 * @param {string} eventType
 * @param {string} updateUrl
 */
function updateProduct(event, eventType, updateUrl) { 

  const $productActions = $('#hwc-js-ProductActions');
  const $quantityWantedInput = $productActions.find('.hwc-js-ProductQtyWanted');
  const formSerialized = $productActions.find('form:first').serialize();
  let preview = psGetRequestParameter('preview');

  if (preview !== null) { 
    preview = '&preview=' + preview;
  } else {
    preview = ''; 
  }

  // Can not get product ajax url
  if (updateUrl === null) {
    showErrorNextToAddtoCartButton();

    return;
  }

  // New request only if new value
 

  if (currentRequestDelayedId) {
    clearTimeout(currentRequestDelayedId);
  }

  // Most update need to occur (almost) instantly, but in some cases (like keyboard actions)
  // we need to delay the update a bit more
  let updateDelay = 30;
  if ('updatedProductQuantity' === eventType) {
    updateDelay = 750;
  }

  currentRequestDelayedId = setTimeout(function updateProductRequest() {

    if (formSerialized === '') {
      return;
    }

    currentRequest = $.ajax({
      url: updateUrl + ((updateUrl.indexOf('?') === -1) ? '?' : '&') + formSerialized + preview,
      method: 'POST',
      data: {
        quickview: $('.modal.quickview.in').length,
        ajax: 1,
        action: 'refresh',
        quantity_wanted: eventType === 'updatedProductCombination' ? $quantityWantedInput.attr('min') : $quantityWantedInput.val()
      },
      dataType: 'json',
      beforeSend() {
        if (currentRequest !== null) {
          currentRequest.abort();
        }
      },
      error(jqXHR, textStatus, errorThrown) {
        if (textStatus !== 'abort'
            && $('section#main > .ajax-error').length === 0
        ) {
          showErrorNextToAddtoCartButton();
        }
      },
      success(data, textStatus, errorThrown) {
        // Avoid image to blink each time we modify the product quantity
        // Can not compare directly cause of HTML comments in data.
        const $newImagesContainer = $('<div>').append(data.product_cover_thumbnails);

        // Used to avoid image blinking if same image = epileptic friendly
        if ($('.quickview #hwc-js-ProductImages, .page-product:not(.modal-open) #hwc-js-ProductImages').html() !== $newImagesContainer.find('.quickview #hwc-js-ProductImages, .page-product:not(.modal-open) #hwc-js-ProductImages').html()) {
          $('.quickview #hwc-js-ProductImages, .page-product:not(.modal-open) #hwc-js-ProductImages').replaceWith(data.product_cover_thumbnails);
        }

        $('.quickview #hwc-js-ProductPrices, .page-product:not(.modal-open) #hwc-js-ProductPrices').first().replaceWith(data.product_prices);
        $('.quickview #hwc-js-ProductCustomization, .page-product:not(.modal-open) #hwc-js-ProductCustomization').first().replaceWith(data.product_customization);
        $('.quickview #hwc-js-ProductVariants, .page-product:not(.modal-open) #hwc-js-ProductVariants').first().replaceWith(data.product_variants);
        $('.quickview #hwc-js-ProductDiscounts, .page-product:not(.modal-open) #hwc-js-ProductDiscounts').first().replaceWith(data.product_discounts);
        $('.quickview #hwc-js-ProductAdditionalInfo, .page-product:not(.modal-open) #hwc-js-ProductAdditionalInfo')
          .first()
          .replaceWith(data.product_additional_info);
        $('.quickview #hwc-js-ProductDetails, .hwc-c-ProductMain #hwc-js-ProductDetails').replaceWith(data.product_details);
        $('.quickview .hwc-js-ProductFlags, .page-product:not(.modal-open) .hwc-js-ProductFlags').first().replaceWith(data.product_flags);
        replaceAddToCartSections(data);
        const minimalProductQuantity = parseInt(data.product_minimal_quantity, 10);

        // Prevent quantity input from blinking with classic theme.
        if (!isNaN(minimalProductQuantity)
            && eventType !== 'updatedProductQuantity'
        ) {
          $quantityWantedInput.attr('min', minimalProductQuantity);
          $quantityWantedInput.val(minimalProductQuantity);
        }
        prestashop.emit('updatedProduct', data);
      },  
      complete(jqXHR, textStatus) {
        currentRequest = null;
        currentRequestDelayedId = null;
      }
    });
  }.bind(currentRequest, currentRequestDelayedId), updateDelay);
}

/**
 * Replace all "add to cart" sections but the quantity input
 * in order to keep quantity field intact i.e.
 *
 * @param {object} data of updated product and cat
 */
function replaceAddToCartSections(data) {
  let $productAddToCart = null;

  $(data.product_add_to_cart).each((index, value) => {
    if ($(value).hasClass('product-add-to-cart')) {
      $productAddToCart = $(value);

      return false;
    } 
  });
  if ($productAddToCart === null) {
    showErrorNextToAddtoCartButton();
  } 
  const $addProductToCart = $('#hwc-js-ProductAddToCart');
  const productAvailabilitySelector = '#hwc-js-ProductAddToCart-add';
  const productAvailabilityMessageSelector = '#hwc-js-ProductAdditionalInfo-availability';
  const productMinimalQuantitySelector = '#hwc-js-ProductAddToCart-minimalqty';
  const productWishlistSelector = '#hwc-js-WishlistAdd';

  replaceAddToCartSection({
    $addToCartSnippet: $productAddToCart,
    $targetParent: $addProductToCart,
    targetSelector: productAvailabilitySelector
  }); 

  replaceAddToCartSection({
    $addToCartSnippet: $productAddToCart,
    $targetParent: $addProductToCart,
    targetSelector: productAvailabilityMessageSelector
  }); 

  replaceAddToCartSection({
    $addToCartSnippet: $productAddToCart,
    $targetParent: $addProductToCart,
    targetSelector: productMinimalQuantitySelector
  });

  replaceAddToCartSection({
    $addToCartSnippet: $productAddToCart,
    $targetParent: $addProductToCart,
    targetSelector: productWishlistSelector
  }); 
}  
 
/**
 * Find DOM elements and replace their content
 *
 * @param {object} replacement Data to be replaced on the current page
 */
function replaceAddToCartSection(replacement) {  
  const destinationObject = $(replacement.$targetParent.find(replacement.targetSelector));
  if (destinationObject.length <= 0) {
    return;
  }
  const replace = replacement.$addToCartSnippet.find(replacement.targetSelector); 
 
  if (replace.length > 0) {
    destinationObject.replaceWith(replace[0].outerHTML);
  } else {
    destinationObject.html('');
  }
}  

/**
 * @param {jQuery} $container
 * @param {string} textError
 */
function showError($container, textError) {
  const $error = $(`<div class="alert alert-danger ajax-error" role="alert">${textError}</div>`);
  $container.replaceWith($error);
}

$(document).ready(() => {
  // Listen on all form elements + those who have a data-product-attribute
  $('body').on(
    'change touchspin.on.startspin',
    '#hwc-js-ProductVariants *[name]',
    (e) => {
      prestashop.emit('updateProduct', {
        eventType: 'updatedProductCombination',
        event: e,
        // Following variables are not used anymore, but kept for backward compatibility
        resp: {},
        reason: {
          productUrl: prestashop.urls.pages.product || '',
        },
      });
    } 
  ); 

  // Refresh all the product content
  prestashop.on('updateProduct', (args) => {
    const eventType = args.eventType;
    const event = args.event;

    getProductUpdateUrl().done(
      productUpdateUrl => updateProduct(event, eventType, productUpdateUrl)
    ).fail(() => {
      if ($('section#main > .ajax-error').length === 0) {
        showErrorNextToAddtoCartButton();
      }
    });
  });

  //HereWeCom - Same as "top" updateProduct but for miniatures/product.tpl
  prestashop.on('updateProductMiniature', (args) => {
    const eventType = args.eventType;
    const event = args.event;
    const reason = args.reason;
 
    getProductUpdateUrl().done(
      productUpdateUrl => updateProductMiniature(event, eventType, productUpdateUrl, reason)
    ).fail(() => {
      if ($('section#main > .ajax-error').length === 0) {
        showErrorNextToAddtoCartButton();
      }
    });
  });
  //

  prestashop.on('updatedProduct', (args) => {
    if (!args.product_url || !args.id_product_attribute) {
      return;
    }

    /*
     * If quickview modal is present we are not on product page, so
     * we don't change the url nor title
     */
    const quickView = $('.modal.quickview');
    if (quickView.length) {
      return;
    }

    let pageTitle = document.title;
    if (args.product_title) {
      pageTitle = args.product_title;
      $(document).attr('title', pageTitle);
    }

    window.history.replaceState(
      {
        id_product_attribute: args.id_product_attribute
      },
      pageTitle,
      args.product_url
    );
  });

  prestashop.on('updateCart', (event) => {
    if (!event || !event.reason || event.reason.linkAction !== 'add-to-cart' ) {
      return;
    }
    const $quantityWantedInput = $('#hwc-js-ProductMain .hwc-js-ProductQtyWanted');
    //Force value to 1, it will automatically trigger updateProduct and reset the appropriate min value if needed
    $quantityWantedInput.val(1); 
  });

  prestashop.on('showErrorNextToAddtoCartButton', (event) => {
    if (!event || !event.errorMessage) {
      return;
    }

    showErrorNextToAddtoCartButton(event.errorMessage);
  });
});
 