import {initSlider} from '../../../utils/slider';
import { getDisplay } from "../../../utils/getDisplay";

export default class VariantsColor {
 
  init(el) {  
    this.el = el; 
 
    this.slider = {
      elm : false,
      state : false,
      config : this.getSliderConfig(this.el),
    };  

    if (getDisplay() != "medium" && getDisplay() != "small") {
      this.slider = initSlider(this.slider);   
      this.setLabel();
    } 
  }  

  setLabel(){
    let variantEl = this.el.closest('.hwc-c-ProductItem-variant');
    let labelEl = variantEl.querySelector('.color_name');
    let checkboxSelectedEl = variantEl.querySelector('.hwc-c-VariantColor-input:checked');
    labelEl.innerHTML = checkboxSelectedEl.dataset.color; 
  }
  
  getSliderConfig(){ 
    return {
      container: this.el,      
      nav: false,
      loop:false, 
      arrowKeys : true, 
      controls : true,
      mouseDrag: true, 
      items : 4,  
    };    
  } 
  
} 

