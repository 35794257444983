import $ from 'jquery';

const currentStepClass = 'js-current-step';
const currentStepSelector = `.${currentStepClass}`;

export default class Steps {
  constructor() {
    this.$steps = $('.hwc-js-CheckoutStep');
    this.$steps.off('click');

    this.$clickableSteps = $(currentStepSelector).prevAll().andSelf();
    this.$clickableSteps.addClass('-clickable');
  }

  getClickableSteps() {
    return this.$clickableSteps;
  }

  makeCurrent(step) { 
    this.$steps.removeClass('-current');
    this.$steps.removeClass(currentStepClass);
    step.makeCurrent();
  }

  static getClickedStep(event) {
    return new Step($(event.target).closest('.hwc-js-CheckoutStep'));
  }
}

class Step {
  constructor($element) {
    this.$step = $element;
  }

  isUnreachable() {
    return this.$step.hasClass('-unreachable');
  }

  makeCurrent() {
    this.$step.addClass('-current');
    this.$step.addClass(currentStepClass);
  }

  hasContinueButton() {
    return $('button.continue', this.$step).length > 0;
  }
 
  disableAllAfter() {
    const $nextSteps = this.$step.nextAll();
    $nextSteps.addClass('-unreachable').removeClass('-complete');
    $('.hwc-js-CheckoutStep-title', $nextSteps).addClass('not-allowed');
  }

  enableAllBefore() {
    const $nextSteps = this.$step.nextAll('.hwc-js-CheckoutStep.-clickable');
    $nextSteps.removeClass('-unreachable').addClass('-complete');
    $('.hwc-js-CheckoutStep-title', $nextSteps).removeClass('not-allowed');
  }
}
